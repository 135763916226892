
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { LearningMateri } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const servicesV1MemberLearning = async () =>
  import(`@/services/v1/member/learning/index`);

export default defineComponent({
  components: {},
  setup() {
    // START === setup breadcrumb =====================================================
    const route = useRoute();
    const { categoryCode, type } = route.params;
    const { learningTitle, topicId, topicTitle } = route.query;
    const typeTitle = type === "ebook" ? "e-Book" : "Video";
    const pageTitle = `${typeTitle} "${learningTitle}" - "${topicTitle}"`;

    const pathMemberMateri = `/member/materi`;
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Materi Pembelajaran",
        to: pathMemberMateri,
      },
      {
        title: `Materi "${learningTitle}"`,
        to: {
          path: `${pathMemberMateri}/${categoryCode}`,
          query: {
            learningTitle,
          },
        },
      },
      {
        title: pageTitle,
        to: `${pathMemberMateri}/${categoryCode}/${type}`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    // START === Function for get list listData ============================================
    const listData = ref([] as any[]);
    const loadingListData = ref(false as boolean);

    const getListData = async () => {
      const {
        getMemberLearningEbooks,
        getMemberLearningVideos,
      } = await servicesV1MemberLearning();

      loadingListData.value = true;

      try {
        let hit;
        if (type === "ebook") {
          hit = await getMemberLearningEbooks(topicId as string);
        } else {
          hit = await getMemberLearningVideos(topicId as string);
        }

        const resData = hit?.response;

        console.log("resData getListData ", resData);

        listData.value = resData;
      } catch (err: any) {
        console.error("err getListData ", err, err?.response);
      } finally {
        loadingListData.value = false;
      }
    };

    const prettierListData = computed(() =>
      listData.value.map((ebook: LearningMateri) => ({
        ...ebook,
        toPageDetail: {
          path:
            type === "ebook"
              ? `/member/materi-ebook/${ebook.slug}`
              : `/member/materi-video/${ebook.slug}`,
        },
      }))
    );

    onMounted(() => {
      getListData();
    });
    // END === Function for get list listData ==============================================

    return {
      pageTitle,
      prettierListData,
      loadingListData,
    };
  },
});
